import React, { useState, useEffect } from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Button, { OutlineButton } from "../button/Button";
import Modal, { ModalContent } from "../modal/Modal";
import tmdbApi, { category, movieType } from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";
import "./hero-slide.scss";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import logo from '../../assets/logo2.png';

const HeroSlide = () => {
  SwiperCore.use([Autoplay]);

  const [movieItems, setMovieItems] = useState([]);

  useEffect(() => {
    const getMovies = async () => {
      const params = { page: 1 };
      try {
        const response = await tmdbApi.getMoviesList(movieType.popular, {
          params,
        });
        setMovieItems(response.results.slice(0, 5));
      } catch (error) {
        console.log("error", error);
      }
    };
    getMovies();
  }, []);

  return (
    <div className="hero-slide">
      <Swiper
        modules={[Autoplay]}
        grabCursor={true}
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{ delay: 4000 }}
      >
        {movieItems.map((item, i) => (
          <SwiperSlide key={i}>
            {({ isActive }) => (
              <HeroSlideItem item={item} isActive={isActive} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      {movieItems.map((item, i) => (
        <TrailerModal key={i} item={item} />
      ))}
    </div>
  );
};

const HeroSlideItem = ({ item, isActive }) => {
  const navigate = useNavigate();

  const background = apiConfig.originalImage(
    item.backdrop_path || item.poster_path
  );

  const keywords = `free movies online, free movie websites, watch free movies, movies, deadpool funko pop, wolverine, spider man, lego marvel, spiderman toys, ted lasso season 3, movie stream, best movies, filmtv, film 2024, streaming film, streaming film gratis, tv series, cinema, watch ${item.title}, watch ${item.title} online, watch ${item.title} online free`;

  const setModalActive = async () => {
    const modal = document.querySelector(`#modal_${item.id}`);

    const videos = await tmdbApi.getVideos(category.movie, item.id);

    if (videos.results.length > 0) {
      const videoSrc = "https://www.youtube.com/embed/" + videos.results[0].key;
      modal
        .querySelector(".modal__content > iframe")
        .setAttribute("src", videoSrc);
    } else {
      modal.querySelector(".modal__content").innerHTML = "No trailer";
    }

    modal.classList.toggle("active");
  };

  return (
    <div
      className={`hero-slide__item ${isActive ? "active" : ""}`}
      style={{ backgroundImage: `url(${background})` }}
    >
      <Helmet>
        <title>Film TV</title>
        <meta name="title" content="Film TV" />
        <meta
          name="description"
          content="Watch HD Movies and TV series online for free. Stream the latest releases in high quality without any cost."
        />
        <meta name="keywords" content={keywords} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://filmtv.site" />
        <meta property="og:url" content="https://www.filmtv.site/" />
        <meta property="og:title" content="Film TV" />
        <meta
          property="og:description"
          content="Watch HD Movies and TV series online for free. Stream the latest releases in high quality without any cost."
        />
        <meta
          property="og:image"
          content={`https://filmtv.site${logo}`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://www.filmtv.site/" />
        <meta property="twitter:title" content="Film TV" />
        <meta
          property="twitter:description"
          content="Watch HD Movies and TV series online for free. Stream the latest releases in high quality without any cost."
        />
        <meta
          property="twitter:image"
          content={`https://filmtv.site${logo}`}
        />
      </Helmet>

      <div className="hero-slide__item__content container">
        <div className="hero-slide__item__content__info">
          <h1 className="title">{item.title}</h1>
          <div className="overview">{item.overview}</div>
          <div className="btns">
            <Button onClick={() => navigate(`/movie/${item.id}`)}>
              WATCH NOW
            </Button>
            <OutlineButton onClick={setModalActive}>
              WATCH TRAILER
            </OutlineButton>
          </div>
        </div>
        <div className="hero-slide__item__content__poster">
          <img src={apiConfig.w500Image(item.poster_path)} alt="" />
        </div>
      </div>
    </div>
  );
};

const TrailerModal = ({ item }) => {
  const iframeRef = React.useRef(null);

  const onClose = () => iframeRef.current.setAttribute("src", "");

  return (
    <Modal active={false} id={`modal_${item.id}`}>
      <ModalContent onClose={onClose}>
        <iframe
          ref={iframeRef}
          width="100%"
          height="500px"
          title="trailer"
        ></iframe>
      </ModalContent>
    </Modal>
  );
};

export default HeroSlide;
