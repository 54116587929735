import axiosClient from './axiosClient';

export const category = {
  movie: 'movie',
  tv: 'tv',
  favourite: 'favourite'
};

export const genres = {
  horror: 'horror',
  scifi: 'scifi',
  adventure: 'adventure',
  anime: 'anime',
  comedy: 'comedy',
  action: 'action',
  animation: 'animation',
  crime: 'crime',
  documentary: 'documentary',
  drama: 'drama',
  family: 'family',
  fantasy: 'fantasy',
  history: 'history',
  music: 'music',
  mystery: 'mystery',
  romance: 'romance',
  'tv-movie': 'tv-movie',
  thriller: 'thriller',
  war: 'war',
  western: 'western'
};

export const movieType = {
  upcoming: 'upcoming',
  popular: 'popular',
  top_rated: 'top_rated',
  now_playing: 'now_playing',
};

export const tvType = {
  popular: 'popular',
  top_rated: 'top_rated',
  on_the_air: 'on_the_air',
};


const tmdbApi = {
  getMoviesList: (type, params) => {
    const url = `movie/${movieType[type]}`;
    return axiosClient.get(url, params);
  },
  getTvList: (type, params) => {
    const url = `tv/${tvType[type]}`;
    return axiosClient.get(url, params);
  },
  getByGenre: (cate, genreId, params) => {
    const url = `discover/${category[cate]}?with_genres=${genreId}`;
    return axiosClient.get(url, params);
  },

  searchByGenre: (genre, genreId, params) => {
    const url = `search/${genres[genre]}?with_genres=${genreId}`;
    return axiosClient.get(url, { params });
  },
  
  getVideos: (cate, id) => {
    const url = `${category[cate]}/${id}/videos`;
    return axiosClient.get(url, { params: {} });
  },
  search: (cate, params) => {
    const url = `search/${cate}`; 
    return axiosClient.get(url, params);
  },
  detail: (cate, id, params) => {
    const url = `${category[cate]}/${id}`;
    return axiosClient.get(url, params);
  },
  credits: (cate, id) => {
    const url = `${category[cate]}/${id}/credits`;
    return axiosClient.get(url, { params: {} });
  },
  similar: (cate, id) => {
    const url = `${category[cate]}/${id}/similar`;
    return axiosClient.get(url, { params: {} });
  },
  season: (cate, id, season_number, params) => {
    const url = `${category[cate]}/${id}/season/${season_number}`;
    return axiosClient.get(url, params);
  },
  getByGenre: (cate, genreId, params) => {
    const url = `discover/${category[cate]}?with_genres=${genreId}`;
    return axiosClient.get(url, params);
  },
};

export default tmdbApi;
