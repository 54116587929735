import React, { useEffect, useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Signin.css";
import { Link, useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth, provider,db } from "../../firebase";
import logo from "../../assets/logo2.png";
import { ref, set, get, update, child  } from "firebase/database";

import { Helmet } from "react-helmet";

export default function Signin() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userEmail = localStorage.getItem("email");

    if (userEmail != null) {
      navigate("/");
    }
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleGoogleSignIn = async () => {
    const auth = getAuth();
    try {
      const result = await signInWithPopup(auth, provider);
      // The signed-in user info.
      const user = result.user;
      console.log("User signed in: ", user);

      const token = await user.getIdToken();

      localStorage.setItem("email", user.email);
      localStorage.setItem("token", token);
      localStorage.setItem("picture", user.photoURL);
      localStorage.setItem("userid", user.uid);
      // Add user data to Realtime Database under 'users' node with UID as key
      const userid = user.uid;
      const usersRef = ref(db, `users/${userid}`);
      await set(usersRef, {
        userEmail: user.email,
        userid: userid,
        createdAt: new Date().toISOString(),
        photoURL: user.photoURL,
        displayName: user.displayName,
      });

      navigate("/profile");
    } catch (error) {
      console.error("Error signing in with Google: ", error);
    }
  };

  const storeUserDataInSession = (token, email) => {
    localStorage.setItem("token", token);

    localStorage.setItem("email", email);
  };

  const SignIn = (e) => {
    e.preventDefault();
    setLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        const user = userCredentials.user;

        if (user.emailVerified) {
          user.getIdToken().then((token) => {
            // Store the token and email in local storage
            storeUserDataInSession(token, email);

            const headers = {
              Authorization: `Bearer ${token}`,
            };

            navigate("/profile");
          });
        } else {
          alert("Please verify your email before logging in.");
          setLoading(false);
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        switch (errorCode) {
          case "auth/invalid-email":
            alert("Invalid email address. Please enter a valid email address.");
            break;

          case "auth/wrong-password":
            alert("Incorrect password. Please enter the correct password.");
            break;

          default:
            alert("Error logging in. Please try again.");
            console.log("Error logging in:", error);
            break;
        }
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <meta name="title" content="SignIn" />
        <meta name="og:title" content="SignIn" />
        <meta
          name="description"
          content="Watch HD Movies and TV series online for free. Stream the latest releases in high quality without any cost."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.filmtv.site/" />
        <meta property="og:image" content={`filmtv.site${logo}`} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://www.filmtv.site/" />
        <meta
          name="keywords"
          content="Free HD movies watch online, High quality videos, High definition videos, Great audio and video quality, Watch 4K quality videos online, Free HD movies watch online, Watch latest super hit movie any time, Download or stream your favourite movie, Bollywood movies, Hollywood movies, Regional cinema, Watch without registration, Watch without sign up, Movies at your fingertips, Watch the best movies here, Unlimited download movies, Watch the latest movies, Movie buffs"
        />
        <meta property="twitter:title" content="SignIn" />
        <meta
          property="twitter:description"
          content="Watch HD Movies and TV series online for free. Stream the latest releases in high quality without any cost."
        />
        <meta property="twitter:image" content={`filmtv.site${logo}`} />
      </Helmet>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            {/* form start */}
            <form className="user" onSubmit={SignIn}>
              <span className="login100-form-title p-b-26">SignIn</span>
              <span className="login100-form-title p-b-48">
                <img src={logo} height={100} width={100}></img>
              </span>

              <div className="wrap-input100 validate-input">
                <input
                  className="input100"
                  type="email"
                  name="email"
                  required
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span
                  className="focus-input100"
                  data-placeholder="email"
                ></span>
              </div>

              <div
                className="wrap-input100 validate-input"
                data-validate="Enter password"
              >
                <span
                  className="btn-show-pass"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <i className="bi bi-eye-slash-fill"></i>
                  ) : (
                    <i className="bi bi-eye-fill"></i>
                  )}
                </span>
                <input
                  className="input100"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  required
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="focus-input100"
                  data-placeholder="Password"
                ></span>
              </div>
              <h6 className="lost-pass">
                Lost your Password?
                <Link to="/reset" className="registerlink">
                  Reset Here
                </Link>
              </h6>
              <button
                className="signinBtn"
                type="submit"
                name="submit"
                disabled={loading}
              >
                {loading ? "Checking..." : "Login"}
              </button>
              <h6 className="register-form-call">
                Don't have an account?
                <Link to="/signup" className="registerlink">
                  SignUp
                </Link>
              </h6>
            </form>
            <div className="google-btn">
              <button
                onClick={handleGoogleSignIn}
                className="google-sign-in-button"
              >
                <svg
                  style={{ marginRight: "10px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  preserveAspectRatio="xMidYMid"
                  viewBox="0 0 256 262"
                  id="google"
                >
                  <path
                    fill="#4285F4"
                    d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                  ></path>
                  <path
                    fill="#34A853"
                    d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                  ></path>
                  <path
                    fill="#FBBC05"
                    d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                  ></path>
                  <path
                    fill="#EB4335"
                    d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                  ></path>
                </svg>
                Continue with Google
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
