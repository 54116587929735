import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import tmdbApi from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";
import "./detail.scss";
import CastList from "./CastList";
import VideoList from "./VideoList";
import Button from "../../components/button/Button";
import MovieList from "../../components/movie-list/MovieList";
import { Helmet } from "react-helmet";
import placeholderImage from "../../assets/placeholder.jpg";
import logo from "../../assets/logo2.png";
import preview from "../../assets/urlpreview.jpg";

// Import Firebase and Realtime Database methods
import { ref, get, update } from "firebase/database";
import { auth, db } from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";

const Detail = () => {
  const { category, id } = useParams();
  const [item, setItem] = useState(null);
  const [bannerImage, setBannerImage] = useState(placeholderImage);
  const [posterImage, setPosterImage] = useState(placeholderImage);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [viewCount, setViewCount] = useState(0);
  const adRef = useRef(null);
  const adRef2 = useRef(null);

  const TRACKING_ID = "G-XZQFY0X5F8";
  ReactGA.initialize(TRACKING_ID);

  const fetchViewCount = async () => {
    try {
      const viewRef = ref(db, "movies/" + id);
      const snapshot = await get(viewRef);
      if (snapshot.exists()) {
        setViewCount(snapshot.val().viewCount || 0);
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.log("Error fetching view count:", error);
    }
  };

  const updateViewCount = async () => {
    try {
      const viewRef = ref(db, "movies/" + id);
      await update(viewRef, {
        viewCount: (viewCount || 0) + 1,
      });
      fetchViewCount();
    } catch (error) {
      console.log("Error updating view count:", error);
    }
  };

  useEffect(() => {
    const loadAd = () => {
      if (adRef.current) {
        adRef.current.innerHTML = "";
      }

      const adScript = document.createElement("script");
      adScript.type = "text/javascript";
      adScript.innerHTML = `
        atOptions = {
          'key' : '9ff14816438fafa02791cf3fcd9ee660',
          'format' : 'iframe',
          'height' : 90,
          'width' : 728,
          'params' : {}
        };
      `;
      if (adRef.current) {
        adRef.current.appendChild(adScript);
      }

      const adScriptSrc = document.createElement("script");
      adScriptSrc.type = "text/javascript";
      adScriptSrc.src =
        "//www.topcreativeformat.com/9ff14816438fafa02791cf3fcd9ee660/invoke.js";
      if (adRef.current) {
        adRef.current.appendChild(adScriptSrc);
      }

      if (adRef2.current) {
        adRef.current.innerHTML = "";
      }

      const adScript2 = document.createElement("script");
      adScript2.type = "text/javascript";
      adScript2.innerHTML = `
      atOptions = {
        'key' : '943ec82b2dedb083df0a8a57cd763797',
        'format' : 'iframe',
        'height' : 50,
        'width' : 320,
        'params' : {}
      };
      `;
      if (adRef2.current) {
        adRef2.current.appendChild(adScript2);
      }

      const adScriptSrc2 = document.createElement("script");
      adScriptSrc2.type = "text/javascript";
      adScriptSrc2.src =
        "//www.topcreativeformat.com/943ec82b2dedb083df0a8a57cd763797/invoke.js";
      if (adRef2.current) {
        adRef2.current.appendChild(adScriptSrc2);
      }
    };

    loadAd();

    fetchViewCount();
    return () => {
      // Clean up any existing ad scripts
      if (adRef.current) {
        adRef.current.innerHTML = "";
      }
    };
  }, [id]);

  useEffect(() => {
    const getDetail = async () => {
      try {
        const response = await tmdbApi.detail(category, id, { params: {} });
        setItem(response);
        const bannerImg = apiConfig.originalImage(
          response.backdrop_path || response.poster_path
        );
        const posterImg = apiConfig.originalImage(
          response.poster_path || response.backdrop_path
        );

        const banner = new Image();
        banner.src = bannerImg;
        banner.onload = () => setBannerImage(bannerImg);
        banner.onerror = () => setBannerImage(placeholderImage);

        const poster = new Image();
        poster.src = posterImg;
        poster.onload = () => setPosterImage(posterImg);
        poster.onerror = () => setPosterImage(placeholderImage);

        window.scrollTo(0, 0);
      } catch (error) {
        console.log("Error fetching details:", error);
      }
    };

    getDetail();
    setCurrentDate(new Date());
  }, [category, id]);

  const handleWatchClick = () => {
    // updateViewCount();
  };

  const isFutureRelease = () => {
    ReactGA.send({
      hitType: item.title || item.name,
      page: window.location.pathname,
    });
    ReactGA.event({
      category: "User",
      action: "View" + item.title || item.name,
      label: "User visited Detail Page",
      value: 99,
      nonInteraction: true,
      transport: "xhr",
    });

    if (item && item.release_date) {
      const releaseDate = new Date(item.release_date);
      return releaseDate > currentDate;
    }

    return false;
  };

  const navigate = useNavigate();

  const handleGenreClick = (genre) => {
    navigate(`/genre/${genre.name}`);
  };

  const keywords = item
    ? `free movies online, free movie websites, watch free movies, movies, deadpool funko pop, wolverine, spider man, lego marvel, spiderman toys, ted lasso season 3, movie stream, best movies, filmtv, film 2024, streaming film, streaming film gratis, tv series, cinema, watch ${item.title}, watch ${item.title} online, watch ${item.title} online free`
    : "";

  return (
    <>
      {item && (
        <Helmet>
          <meta charSet="utf-8" />

          <title>{item.title || item.name} - Watch Online Free | Film TV</title>

          <meta
            name="keywords"
            content={`${item.title || item.name}, Watch ${
              item.title || item.name
            }, Watch Movie, ${keywords}, How to watch ${
              item.title || item.name
            } online for free?, How to watch Hollywood movies online?, Where can I watch the latest movies for free?`}
          />
          <meta
            name="description"
            content={`Watch ${
              item.title || item.name
            } and other latest movies and TV shows on Film TV. Enjoy high-quality streaming of ${
              item.title || item.name
            }, Hollywood blockbusters, and trending series. Learn how to watch ${
              item.title || item.name
            } online for free and stay updated with the newest releases.`}
          />
          <meta property="og:type" content="website" />
          <link
            rel="canonical"
            href={`https://www.filmtv.site/${category}/${id}`}
          />
          <meta
            property="og:url"
            content={`https://www.filmtv.site/${category}/${id}`}
          />
          <meta property="og:title" content={item.title || item.name + ' Watch Online Free | Film Tv' } />
          <meta
            property="og:description"
            content={
              item.overview ||
              "Watch the latest movies and TV shows on Film TV."
            }
          />
          <meta
            property="og:image"
            content={apiConfig.originalImage(
              item.poster_path || item.backdrop_path
            )}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />

          <meta property="twitter:card" content="summary" />
          <meta
            property="twitter:url"
            content={`https://www.filmtv.site/${category}/${id}`}
          />
          <meta property="twitter:title" content={item.title || item.name} />
          <meta
            property="twitter:description"
            content={`Watch ${
              item.title || item.name
            } and other latest movies and TV shows on Film TV. Enjoy high-quality streaming of ${
              item.title || item.name
            }, Hollywood blockbusters, and trending series. Learn how to watch ${
              item.title || item.name
            } online for free and stay updated with the newest releases.`}
          />
          <meta
            property="twitter:image"
            content={apiConfig.originalImage(
              item.poster_path || item.backdrop_path
            )}
          />
        </Helmet>
      )}

      {item && (
        <>
          <div
            className="banner"
            style={{
              backgroundImage: `url(${bannerImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <div className="mb-3 movie-content container">
            <div className="movie-content__poster">
              <div
                className="movie-content__poster__img"
                style={{
                  backgroundImage: `url(${posterImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
            <div className="movie-content__info">
              <h1 className="title">{item.title || item.name}</h1>
              <div className="genres">
                {isFutureRelease() ? (
                  <Button className="small" disabled>
                    Coming Soon
                  </Button>
                ) : (
                  <Link to={`/watch/${category}/${id}`}>
                    <Button className="small" onClick={handleWatchClick}>
                      Watch
                    </Button>
                  </Link>
                )}
                {item.genres &&
                  item.genres.slice(0, 3).map((genre, i) => (
                    <span
                      key={i}
                      className="genres__item"
                      onClick={() => handleGenreClick(genre)}
                      style={{ cursor: "pointer" }} // Add pointer cursor for better UX
                    >
                      {genre.name}
                    </span>
                  ))}

                {/* Show view count here */}
                <p style={{ marginTop: "10px" }}>
                  <FontAwesomeIcon icon={faEye} /> {formatNumber(viewCount)}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ color: "yellow" }}>
                  TMDB Rating: {item.vote_average.toFixed(1)}
                </p>
                <p style={{ marginLeft: "20px" }}>
                  Runtime: {item.runtime} min
                </p>
                <p style={{ marginLeft: "20px" }}>
                  Language: {item.original_language}
                </p>
                <p style={{ marginLeft: "20px" }}>Date: {item.release_date}</p>
              </div>

              <div
                ref={adRef2}
                className="ad-container mb-3"
                style={{ display: "flex", justifyContent: "start" }}
              ></div>
              <p className="overview">{item.overview}</p>
              <div className="cast">
                <div className="section__header">
                  <h2>Casts</h2>
                </div>
                <CastList id={item.id} />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section mb-3">
              <VideoList id={item.id} />
            </div>
            <div
              ref={adRef}
              className="ad-container section mb-3"
              style={{ display: "flex", justifyContent: "center" }}
            ></div>
            <div className="section mb-3">
              <div className="section__header mb-2">
                <h2>Similar</h2>
              </div>
              <MovieList category={category} type="similar" id={item.id} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

const formatNumber = (number) => {
  if (number >= 1_000_000) {
    return (number / 1_000_000).toFixed(1) + "m";
  } else if (number >= 1_000) {
    return (number / 1_000).toFixed(1) + "k";
  } else {
    return number;
  }
};
export default Detail;
