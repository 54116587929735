import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './movie-list.scss';

import { SwiperSlide, Swiper } from 'swiper/react';
import MovieCard from '../movie-card/MovieCard';

const FavouriteList = ({ category }) => {
    const [hasFavorites, setHasFavorites] = useState(false);
    const [items, setItems] = useState([]);
    const userEmail = localStorage.getItem("email");
  
    useEffect(() => {
        const storedFavorites = JSON.parse(localStorage.getItem(userEmail)) || [];
        setHasFavorites(storedFavorites.length > 0);

        // Filter favorites by category if needed
        const filteredItems = category ? storedFavorites.filter(favorite => favorite.category === category) : storedFavorites;
      

        // Sort items by timestamp in descending order (newest first)
        filteredItems.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setItems(filteredItems);
    }, [category, userEmail]); // Refresh when category or userEmail changes

    return (
        <div className="movie-list">
            <Swiper
                grabCursor={true}
                spaceBetween={10}
                slidesPerView={'auto'}
            >
                {items.map((item, i) => (
                    
                    <SwiperSlide key={i}>
                        
                        <MovieCard item={item} category={item.category} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

FavouriteList.propTypes = {
    category: PropTypes.string,
};

export default FavouriteList;
