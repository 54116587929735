import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export default function Url() {
  const { category, id } = useParams();
  const [htmlContent, setHtmlContent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://vidsrc.to/embed/${category}/${id}`);

        // Check for a successful response (status codes in the range 200-299)
        if (response.status >= 200 && response.status < 300) {
          setHtmlContent(response.data);
        } else if (response.status === 404) {
          // Handle 404 (Not Found) by setting the error state
          setError(`Error: ${response.data}`);
        } else {
          // Handle other non-successful responses
          console.error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching data:', error);

        if (error.response && error.response.status === 404) {
          // Handle 404 (Not Found) by setting the error state
          setError(`Error: ${error.response.data}`);
        } else {
          setError('An error occurred while fetching data.');
        }
      }
    };

    fetchData();
  }, [category, id]);

  return (
    <div>
      <p>Constructed Link: {`https://vidsrc.to/embed/${category}/${id}`}</p>

      {error ? (
        <p>{error}</p>
      ) : (
        htmlContent && (
          <div>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        )
      )}
    </div>
  );
}
