import React from 'react';
import { useParams } from 'react-router';
import PageHeader from '../components/page-header/PageHeader';
import MovieGrid from '../components/movie-grid/MovieGrid';
import tmdbApi, { category as cate } from '../api/tmdbApi';
import GenreGride from '../components/movie-grid/GenreGride';
import FavouriteGride from '../components/movie-grid/FavouriteGride';

const Catalog3 = () => {
  const { category } = useParams();



  return (
    <>
      <PageHeader>
        {"favourite" === 'favourite'
          ? 'Favourites'
      
      
          : ''}
      </PageHeader>
      <div className="container">
        <div className="section mb-3">
          <FavouriteGride category={category}  />
        </div>
      </div>
    </>
  );
};

export default Catalog3;
