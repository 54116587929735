import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './live-list.scss';
import { SwiperSlide, Swiper } from 'swiper/react';
import LiveTvCard from '../movie-card/LiveTvCard';
import actionImage from '../../assets/rakuetn_action.png';
import comedyImage from '../../assets/rakuten_comedy.jpeg';
import familyImage from '../../assets/rakuten_family.jpeg';

const LiveTvList = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        // Define channels with URLs and images
        const channels = [
            {
                title: 'Rakuten Action',
                url: 'https://live.filmtv.site/rakuten.html',
                channel:"rakutenaction",
                image: actionImage
            },
            {
                title: 'Rakuten Comedy ',
                url: 'https://live.filmtv.site/rakutencomedy.html',
                channel:"rakutencomedy",
                image: comedyImage
            },
            {
                title: 'Rakuten Family ',
                url: 'https://live.filmtv.site/rakutenfamily.html',
                channel:"rakutenfamily",
                image: familyImage
            }
        ];

        setItems(channels);
    }, []);

    return (
        <div className="live-list">
            <Swiper
                grabCursor={true}
                spaceBetween={10}
                slidesPerView={'auto'}
            >
                {items.map((item, i) => (
                    <SwiperSlide key={i}>
                        <LiveTvCard item={item} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

LiveTvList.propTypes = {
    category: PropTypes.string,
};

export default LiveTvList;
