// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth ,GoogleAuthProvider,signInWithPopup} from "firebase/auth";
import {getDatabase} from 'firebase/database';
const firebaseConfig = {
  apiKey: "AIzaSyDlhGv-9SPgXF6Wl56x8M_2PCgyjiSMDxY",
  authDomain: "artone-5b851.firebaseapp.com",
  projectId: "artone-5b851",
  storageBucket: "artone-5b851.appspot.com",
  messagingSenderId: "792218548234",
  appId: "1:792218548234:web:6ed631a81c778e3f3a47f3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const provider = new GoogleAuthProvider(); 