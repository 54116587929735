import React, { useRef, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./header.scss";
import logo from "../../assets/logo2.png";

const headerNav = [
  { display: "Home", path: "/" },
  { display: "Movies", path: "/movie" },
  { display: "TV Series", path: "/tv" },
  { display: "Genres", path: "#" },
  { display: "SignIn", path: "/signin", icon: "person-circle" },
];

const genres = [
  { id: "action", name: "Action" },
  { id: "adventure", name: "Adventure" },
  { id: "anime", name: "Animation" },
  { id: "comedy", name: "Comedy" },
  { id: "crime", name: "Crime" },
  { id: "documentary", name: "Documentary" },
  { id: "drama", name: "Drama" },
  { id: "family", name: "Family" },
  { id: "fantasy", name: "Fantasy" },
  { id: "history", name: "History" },
  { id: "horror", name: "Horror" },
  { id: "music", name: "Music" },
  { id: "mystery", name: "Mystery" },
  { id: "romance", name: "Romance" },
  { id: "scifi", name: "Science Fiction" },
  { id: "tv-movie", name: "TV Movie" },
  { id: "thriller", name: "Thriller" },
  { id: "war", name: "War" },
  { id: "western", name: "Western" }
];

const Header = () => {
  const { pathname } = useLocation();
  const headerRef = useRef(null);
  const [isGenresOpen, setIsGenresOpen] = useState(false);
  const userToken = localStorage.getItem("token");
  const userPhoto = localStorage.getItem("picture");
  const navigate = useNavigate();
  const active = headerNav.findIndex((e) => e.path === pathname);

  useEffect(() => {
    const shrinkHeader = () => {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        headerRef.current.classList.add("shrink");
      } else {
        headerRef.current.classList.remove("shrink");
      }
    };
    window.addEventListener("scroll", shrinkHeader);
    return () => {
      window.removeEventListener("scroll", shrinkHeader);
    };
  }, []);

  const handleProfileClick = () => {
    if (userToken) {
      navigate("/profile");
    } else {
      navigate("/signin");
    }
  };

  const handleGenresChange = (event) => {
    const selectedGenre = event.target.value;
    navigate(`/genre/${selectedGenre}`);
  };

  return (
    <div ref={headerRef} className="header">
      <div className="header__wrap container">
        <div className="logo">
          <img src={logo} alt="FilmTv logo" />
          <Link to="/">FilmTV</Link>
        </div>
        <ul className="header__nav">
          {headerNav.map((e, i) => (
            <li key={i} className={`${i === active ? "active" : ""}`}>
              {e.path === "/signin" ? (
                userToken ? (
                  <div className="small">
                    <Link to="/profile" className="nav-link">
                      <img
                        src={userPhoto || "https://icon-library.com/images/avatar-icon-images/avatar-icon-images-4.jpg"}
                        alt="Profile"
                        style={{ width: "30px", borderRadius: "50%" }}
                      />
                    </Link>
                  </div>
                ) : (
                  <div
                    className="small"
                    style={{ cursor: "pointer", background: "none" }}
                    onClick={handleProfileClick}
                  >
                    {e.icon && <i className={`bi-${e.icon}`}></i>} {e.display}
                  </div>
                )
              ) : e.display === "Genres" ? (
                <select 
                  className="nav-link"
                  value={pathname.split("/")[2]}
                  onChange={handleGenresChange}
                  onFocus={() => setIsGenresOpen(true)}
                  onBlur={() => setIsGenresOpen(false)}
                >
                  <option value="" className="options">Genres</option>
                  {genres.map((genre) => (
                    <option key={genre.id} value={genre.id} className="options">
                      {genre.name}
                    </option>
                  ))}
                </select>
              ) : (
                <Link to={e.path} className="nav-link">
                  {e.icon && <i className={`bi-${e.icon}`}></i>} {e.display}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Header;
