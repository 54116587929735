import React, { useState, useEffect, useRef } from 'react';
import { getDatabase, ref, get, update, child } from 'firebase/database';
import { db } from '../../firebase'; // Ensure this import points to your Firebase configuration file

const PlayerPage = () => {
  const [friendId, setFriendId] = useState('');
  const [friendsList, setFriendsList] = useState([]);
  const userEmail = localStorage.getItem("email");
  const uId = localStorage.getItem('uid');

  useEffect(() => {
    if (!userEmail || !uId) {
      // Redirect to login if user details are not found in local storage
      window.location.href = '/login';
    } else {
      fetchFriendsList();
    }
  }, [userEmail, uId]);

  const fetchFriendsList = async () => {
    const dbRef = ref(getDatabase());
    const userRef = child(dbRef, `users/${uId}`);
    try {
      const userSnapshot = await get(userRef);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        if (userData.friends) {
          const friendsPromises = userData.friends.map(async (friendId) => {
            const friendRef = child(dbRef, `users/${friendId}`);
            const friendSnapshot = await get(friendRef);
            return { id: friendId, ...friendSnapshot.val() };
          });
          const friends = await Promise.all(friendsPromises);
          setFriendsList(friends);
        }
      } else {
        console.log('User not found');
      }
    } catch (error) {
      console.error('Error fetching friends list:', error);
    }
  };

  const addFriend = async () => {
    if (friendId) {
      const dbRef = ref(getDatabase());
      const friendRef = child(dbRef, `users/${friendId}`);
      
      try {
        const friendSnapshot = await get(friendRef);
        if (friendSnapshot.exists()) {
          const userRef = child(dbRef, `users/${uId}`);
          const userSnapshot = await get(userRef);

          if (userSnapshot.exists()) {
            const userData = userSnapshot.val();
            const updatedFriends = userData.friends ? [...userData.friends, friendId] : [friendId];

            await update(userRef, { friends: updatedFriends });
            await update(friendRef, { addedBy: { id: uId, email: userEmail } }); // Store who added the friend
            alert('Friend added successfully');
            fetchFriendsList(); // Refresh the friends list after adding a new friend
          } else {
            alert('User not found');
          }
        } else {
          alert('Friend not found');
        }
      } catch (error) {
        console.error('Error adding friend:', error);
        alert('Error adding friend. Please try again.');
      }
    }
  };

  const updateFriendVideoState = async (event, currentTime) => {
    const dbRef = ref(getDatabase());
    for (const friend of friendsList) {
      const friendRef = child(dbRef, `users/${friend.id}`);
      await update(friendRef, { videoState: { event, currentTime } });
    }
  };

  return (
    <div style={{ marginTop: '250px' }}>
      <h2>Player Page</h2>
      <div>
        <input
          type="text"
          value={friendId}
          onChange={(e) => setFriendId(e.target.value)}
          placeholder="Enter friend's user ID"
        />
        <button onClick={addFriend}>Add Friend</button>
      </div>
      <h3>Friends List</h3>
      <ul>
        {friendsList.map((friend) => (
          <li key={friend.id}>
            {friend.id} - {friend.userEmail}
          </li>
        ))}
      </ul>
      <iframe
        src="https://drive.google.com/file/d/1-LG-k-rrO445h4XK7TGwgdNPQg4bynoa/preview"
        width="640"
        height="480"
        allow="autoplay"
        title="Video Player"
      ></iframe>
    </div>
  );
};

export default PlayerPage;
