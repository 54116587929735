import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './movie-grid.scss';
import MovieCard from '../movie-card/MovieCard';
import Button, { OutlineButton } from '../button/Button';
import Input from '../input/Input';
import { useParams } from 'react-router-dom';
import tmdbApi, { category, movieType, tvType, genres } from '../../api/tmdbApi';
import GenreCard from '../movie-card/GenreCard';
import { Helmet } from 'react-helmet';
import logo from '../../assets/preview.png'

const GenreGride = (props) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const { keyword } = useParams();

  useEffect(() => {
    const getList = async () => {
      let response = null;
      if (keyword === undefined) {
        const params = {};
        switch (props.genres) {
          case genres.horror:
            response = await tmdbApi.getByGenre('movie', '27', { params });
            break;
          case genres.anime:
            response = await tmdbApi.getByGenre('movie', '16', { params });
            break;
          case genres.adventure:
            response = await tmdbApi.getByGenre('movie', '12', { params });
            break;
          case genres.scifi:
            response = await tmdbApi.getByGenre('movie', '878', { params });
            break;
          case genres.comedy:
            response = await tmdbApi.getByGenre('movie', '35', { params });
            break;
          case genres.action:
            response = await tmdbApi.getByGenre('movie', '28', { params });
            break;
          case genres.animation:
            response = await tmdbApi.getByGenre('movie', '16', { params });
            break;
          case genres.crime:
            response = await tmdbApi.getByGenre('movie', '80', { params });
            break;
          case genres.documentary:
            response = await tmdbApi.getByGenre('movie', '99', { params });
            break;
          case genres.drama:
            response = await tmdbApi.getByGenre('movie', '18', { params });
            break;
          case genres.family:
            response = await tmdbApi.getByGenre('movie', '10751', { params });
            break;
          case genres.fantasy:
            response = await tmdbApi.getByGenre('movie', '14', { params });
            break;
          case genres.history:
            response = await tmdbApi.getByGenre('movie', '36', { params });
            break;
          case genres.music:
            response = await tmdbApi.getByGenre('movie', '10402', { params });
            break;
          case genres.mystery:
            response = await tmdbApi.getByGenre('movie', '9648', { params });
            break;
          case genres.romance:
            response = await tmdbApi.getByGenre('movie', '10749', { params });
            break;
          case genres['tv-movie']:
            response = await tmdbApi.getByGenre('movie', '10770', { params });
            break;
          case genres.thriller:
            response = await tmdbApi.getByGenre('movie', '53', { params });
            break;
          case genres.war:
            response = await tmdbApi.getByGenre('movie', '10752', { params });
            break;
          case genres.western:
            response = await tmdbApi.getByGenre('movie', '37', { params });
          default:
            response = await tmdbApi.getTvList(tvType.popular, { params });
        }
      } else {
        const params = {
          query: keyword,
        };
        response = await tmdbApi.search(props.category, { params });
      }
      console.log("Response of Result :", response.results);
      setItems(response.results);
      setTotalPage(response.total_pages);
    };
    getList();
  }, [props.category, keyword, props.genres]); // Include props.genres in the dependency array

  const loadMore = async () => {
    let response = null;
    if (keyword === undefined) {
      const params = {
        page: page + 1,
      };
      switch (props.genres) {
       case genres.horror:
      response = await tmdbApi.getByGenre('movie', '27', { params });
      break;
    case genres.anime:
      response = await tmdbApi.getByGenre('movie', '16', { params });
      break;
    case genres.adventure:
      response = await tmdbApi.getByGenre('movie', '12', { params });
      break;
    case genres.scifi:
      response = await tmdbApi.getByGenre('movie', '878', { params });
      break;
    case genres.comedy:
      response = await tmdbApi.getByGenre('movie', '35', { params });
      break;
    case genres.action:
      response = await tmdbApi.getByGenre('movie', '28', { params });
      break;
    case genres.animation:
      response = await tmdbApi.getByGenre('movie', '16', { params });
      break;
    case genres.crime:
      response = await tmdbApi.getByGenre('movie', '80', { params });
      break;
    case genres.documentary:
      response = await tmdbApi.getByGenre('movie', '99', { params });
      break;
    case genres.drama:
      response = await tmdbApi.getByGenre('movie', '18', { params });
      break;
    case genres.family:
      response = await tmdbApi.getByGenre('movie', '10751', { params });
      break;
    case genres.fantasy:
      response = await tmdbApi.getByGenre('movie', '14', { params });
      break;
    case genres.history:
      response = await tmdbApi.getByGenre('movie', '36', { params });
      break;
    case genres.music:
      response = await tmdbApi.getByGenre('movie', '10402', { params });
      break;
    case genres.mystery:
      response = await tmdbApi.getByGenre('movie', '9648', { params });
      break;
    case genres.romance:
      response = await tmdbApi.getByGenre('movie', '10749', { params });
      break;
    case genres['tv-movie']:
      response = await tmdbApi.getByGenre('movie', '10770', { params });
      break;
    case genres.thriller:
      response = await tmdbApi.getByGenre('movie', '53', { params });
      break;
    case genres.war:
      response = await tmdbApi.getByGenre('movie', '10752', { params });
      break;
    case genres.western:
      response = await tmdbApi.getByGenre('movie', '37', { params });
      break;
        default:
          response = await tmdbApi.getTvList(tvType.popular, { params });
    } }else {
      const params = {
        page: page + 1,
        query: keyword,
      };
      response = await tmdbApi.search(props.category, { params });
    }
    setItems([...items, ...response.results]);
    setPage(page + 1);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
    
    <Helmet>
    
      <title>{capitalizeFirstLetter(props.genres)}</title>
        <meta name="title" content={capitalizeFirstLetter(props.genres)} />
        <meta name="description" content={`Watch the best ${props.genres} movies and TV shows online for free in HD. Enjoy an extensive collection of ${props.genres} content, from classic hits to the latest releases, all at Film TV.`} />


        <meta property="og:type" content="website" />
        <link rel="canonical" href={`https://www.filmtv.site/${props.genres}`} />
        <meta property="og:url" content={`https://www.filmtv.site/${props.genres}`}/>
        <meta property="og:title" content={capitalizeFirstLetter(props.genres)}/>
        <meta property="og:description" content={`Watch the best ${props.genres} movies and TV shows online for free in HD. Enjoy an extensive collection of ${props.genres} content, from classic hits to the latest releases, all at Film TV.`}   />
        <meta property="og:image" itemProp="image" content={logo} />
        
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content={`https://www.filmtv.site/${props.genres}`} />
        <meta property="twitter:title" content={capitalizeFirstLetter(props.genres)} />
        <meta
          property="twitter:description"
          content={`Watch the best ${props.genres} movies and TV shows online for free in HD. Enjoy an extensive collection of ${props.genres} content, from classic hits to the latest releases, all at Film TV.`} 
        />
        <meta
          property="twitter:image"
          content={logo}
        />
      </Helmet>

      <div className="movie-grid">
        {items.map((item, i) => (
          <GenreCard category={props.category} item={item} genreType={props.genres} key={i} />
        ))}
      </div>
      {page < totalPage ? (
        <div className="movie-grid__loadmore">
          <OutlineButton className="small" onClick={loadMore}>
            Load more
          </OutlineButton>
        </div>
      ) : null}
    </>
  );
};



export default GenreGride;
