import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './movie-grid.scss';

import MovieCard from '../movie-card/MovieCard';
import Button, { OutlineButton } from '../button/Button';
import Input from '../input/Input';
import tmdbApi, { category, movieType, tvType } from '../../api/tmdbApi';
import logo from '../../assets/preview.png';

const FavouriteGride = (props) => {
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const { keyword } = useParams();

    useEffect(() => {
        // Fetch favorites from local storage
        const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
        
        // Filter favorites by category if needed
        const filteredItems = props.category ? storedFavorites.filter(favorite => favorite.category === props.category) : storedFavorites;
        
        // Sort items by timestamp in descending order (newest first)
        filteredItems.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        
        setItems(filteredItems);
    }, [props.category]); // Refresh when category changes

    const loadMore = async () => {
        let response = null;
        
        if (keyword === undefined) {
            const params = { page: page + 1 };
            
            switch (props.category) {
                case category.movie:
                    response = await tmdbApi.getMoviesList(movieType.upcoming, { params });
                    break;
                default:
                    response = await tmdbApi.getTvList(tvType.popular, { params });
            }
        } else {
            const params = { page: page + 1, query: keyword };
            response = await tmdbApi.search(props.category, { params });
        }
        
        setItems([...items, ...response.results]);
        setPage(page + 1);
        setTotalPage(response.total_pages);
    };

    return (
        <>
           <Helmet>
           <title>Favourites</title>
           </Helmet>
            <div className="movie-grid">
                {items.map((item, i) => (
                    <MovieCard category={props.category} item={item} key={i} />
                ))}
            </div>
            {page < totalPage ? (
                <div className="movie-grid__loadmore">
                    <OutlineButton className="small" onClick={loadMore}>
                        Load more
                    </OutlineButton>
                </div>
            ) : null}
        </>
    );
}

export default FavouriteGride;
