import React from 'react';
import { useParams } from 'react-router';
import PageHeader from '../components/page-header/PageHeader';
import GenreGride from '../components/movie-grid/GenreGride';
import { genres as gen } from '../api/tmdbApi';

const genreDisplayName = (genre) => {

  return genre === "horror"
    ? "Horror"
    : genre === "anime"
    ? "Anime"
    : genre === "scifi"
    ? "Sci-Fi"
    : genre === "adventure"
    ? "Adventure"
    : genre === "comedy"
    ? "Comedy"
    : genre === "action"
    ? "Action"
    : genre === "anime"
    ? "anime"
    : genre === "crime"
    ? "Crime"
    : genre === "documentary"
    ? "Documentary"
    : genre === "drama"
    ? "Drama"
    : genre === "family"
    ? "Family"
    : genre === "fantasy"
    ? "Fantasy"
    : genre === "history"
    ? "History"
    : genre === "music"
    ? "Music"
    : genre === "mystery"
    ? "Mystery"
    : genre === "romance"
    ? "Romance"
    : genre === "tv-movie"
    ? "TV Movie"
    : genre === "thriller"
    ? "Thriller"
    : genre === "war"
    ? "War"
    : genre === "western"
    ? "Western"
    : "";
};



const Catalog2 = () => {
  const { genre } = useParams(); // Changed to match the route definition

  return (
    <>
      <PageHeader>
        {genreDisplayName(genre)}
      </PageHeader>
      <div className="container">
        <div className="section mb-3">
          <GenreGride genres={genre} />
        </div>
      </div>
    </>
  );
};

export default Catalog2;
