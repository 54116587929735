import React, { useEffect, useState } from 'react';
import moment from 'moment';

const Live = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoUrl = "https://drive.google.com/file/d/1-LG-k-rrO445h4XK7TGwgdNPQg4bynoa/preview";
  const targetTime = moment("23:40", "HH:mm"); // Set the target time (9:30 PM)

  useEffect(() => {
    const checkTimeAndPlay = () => {
      const currentTime = moment();
      if (currentTime.isSameOrAfter(targetTime)) {
        setIsPlaying(true);
      }
    };

    checkTimeAndPlay();
    const interval = setInterval(checkTimeAndPlay, 1000); // Check every second

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <h1>Live</h1>
      {isPlaying ? (
        <div style={{ position: 'relative', paddingTop: '56.25%' }}>
          <iframe
            src={videoUrl}
            style={{
              position: 'absolute',
              top: 0,
              autoplay:true,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ) : (
        <p>The video will play at 9:30 PM.</p>
      )}
    </div>
  );
};

export default Live;
