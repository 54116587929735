import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from '../pages/Home';
import Catalog from '../pages/Catalog';
import Catalog2 from '../pages/Catalog2';
import Detail from '../pages/detail/Detail';
import Player from '../components/player/Player';
import Signin from '../components/SignIn/Signin';
import Signup from '../components/SignIn/Signup';
import Url from '../components/SignIn/Url';
import Lostpass from '../components/SignIn/Lostpass';
import Profile from '../components/SignIn/Profile';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import ErrorPage from '../components/404/error';
import Catalog3 from '../pages/Catalog3';
import PlayerPage from '../components/realtime/PlayerPage';
import FriendPage from '../components/realtime/FriendPage';
import LivePlayer from '../components/player/LivePlayer';
import Live from '../components/realtime/Live';

const AppRoutes = () => {
    const location = useLocation();
    const hideHeaderFooterRoutes = ['/error']; // Add paths where you want to hide header and footer

    const shouldHideHeaderFooter = hideHeaderFooterRoutes.includes(location.pathname);

    return (
        <>
            {!shouldHideHeaderFooter && (
                <>
                    <Header />
                </>
            )}
            <Routes>
            <Route path='/:category/search/:keyword' element={<Catalog />} />
                <Route path='/realtime/' element={<PlayerPage/>} />
                <Route path='/realtime/friend' element={<FriendPage/>} />
                <Route path='/:category/:id' element={<Detail />} />
                <Route path='/genre/:genre' element={<Catalog2 />} />
                <Route path='/favourites' element={<Catalog3/>}/>
                <Route path='/:category' element={<Catalog />} />
                <Route path='/' element={<Home />} />
                <Route path='/signin' element={<Signin />} />
                <Route path='/signup' element={<Signup />} />
                <Route path='/reset' element={<Lostpass />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/error' element={<ErrorPage />} />
                <Route path='/urltest/:category/:id' element={<Url />} />
                <Route path='/watch/:category/:id' element={<Player />} />
            </Routes>

            {!shouldHideHeaderFooter && (
                <>
                    <Footer />
                </>
            )}
        </>
    );
}

export default AppRoutes;
