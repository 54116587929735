import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import debounce from "lodash.debounce";
import { OutlineButton } from "../components/button/Button";
import HeroSlide from "../components/hero-slide/HeroSlide";
import MovieList from "../components/movie-list/MovieList";
import FavouriteList from "../components/movie-list/FavouriteList";
import LiveTvList from "../components/movie-list/LiveTvList";
import Input from "../components/input/Input";
import ReactGA from "react-ga4";
import { category, movieType, tvType } from "../api/tmdbApi";
import logo from '../assets/logo2.png';
import tmdbApi from "../api/tmdbApi";
import placeholder from "../assets/placeholder.jpg";

const Home = () => {
  const userEmail = localStorage.getItem("email");
  const [hasFavorites, setHasFavorites] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const adRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem(userEmail)) || [];
    setHasFavorites(storedFavorites.length > 0);

    ReactGA.send({ hitType: "Home Page Views", page: window.location.pathname });
    ReactGA.event({
      category: "User",
      action: "Home Page View",
      label: "User visited Home Page",
      value: 99,
      nonInteraction: true,
      transport: "xhr",
    });

    
    const loadAd = () => {
      if (adRef.current) {
        adRef.current.innerHTML = ""; 

        const adScript = document.createElement("script");
        adScript.type = "text/javascript";
        adScript.innerHTML = `
          atOptions = {
            'key' : '9ff14816438fafa02791cf3fcd9ee660',
            'format' : 'iframe',
            'height' : 90,
            'width' : 728,
            'params' : {}
          };
        `;
        adRef.current.appendChild(adScript);

        const adScriptSrc = document.createElement("script");
        adScriptSrc.type = "text/javascript";
        adScriptSrc.src = "//www.topcreativeformat.com/9ff14816438fafa02791cf3fcd9ee660/invoke.js";
        adRef.current.appendChild(adScriptSrc);
      }
    };

    loadAd();

    return () => {
      if (adRef.current) {
        adRef.current.innerHTML = ""; // Clean up on unmount
      }
    };
  }, [userEmail]);

  const fetchSuggestions = useCallback(
    debounce(async (query) => {
      if (query.trim().length > 0) {
        const params = { query };

        try {
          const [movieResponse, tvResponse] = await Promise.all([
            tmdbApi.search('movie', { params }),
            tmdbApi.search('tv', { params })
          ]);

          const combinedResults = [
            ...movieResponse.results.map(item => ({ ...item, media_type: 'movie' })),
            ...tvResponse.results.map(item => ({ ...item, media_type: 'tv' }))
          ];

          setSuggestions(combinedResults);
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]);
      }
    }, 300),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setKeyword(value);
    fetchSuggestions(value);
  };

  const goToSearch = () => {
    if (keyword.trim().length > 0) {
      navigate(`/movie/search/${keyword}`);
      setSuggestions([]);
    }
  };

  useEffect(() => {
    const enterEvent = (e) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        goToSearch();
      }
    };
    document.addEventListener("keyup", enterEvent);
    return () => {
      document.removeEventListener("keyup", enterEvent);
    };
  }, [keyword, goToSearch]);

  return (
    <>
      <Helmet>
        <title>Film TV</title>
        <meta name="title" content="Film TV" />
        <meta name="description" content="Watch the latest movies and TV shows on Film TV for Free." />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://filmtv.site" />
        <meta property="og:url" content="https://www.filmtv.site/" />
        <meta property="og:title" content="Film TV" />
        <meta property="og:description" content="Watch the latest movies and TV shows on Film TV for Free." />
        <meta property="og:image" content={`filmtv.site${logo}`} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:url" content="https://www.filmtv.site/" />
        <meta property="twitter:title" content="Film TV" />
        <meta property="twitter:description" content="Watch the latest movies and TV shows on Film TV for Free." />
        <meta property="twitter:image" content={`filmtv.site${logo}`} />
      </Helmet>

      <HeroSlide />
      <div className="container">
        <div className="section mb-3">
          <div className="section__header mb-2"></div>
        </div>

        <div className="section mb-3" style={{ justifyContent: "end", display: "flex" }}>
          <div className="movie-search">
            <Input
              type="text"
              placeholder="Enter keyword"
              value={keyword}
              onChange={handleInputChange}
            />
            <OutlineButton className="small" onClick={goToSearch}>
              Search
            </OutlineButton>
            {suggestions.length > 0 && (
              <div className="suggestions">
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="suggestion"
                    onClick={() => {
                      setKeyword(suggestion.title);
                      setSuggestions([]);
                      navigate(
                        suggestion.media_type === 'movie' 
                          ? `/movie/${suggestion.id}` 
                          : `/tv/${suggestion.id}`
                      );
                    }}
                  >
                    <div className='show-container'> 
                      <img
                        src={
                          suggestion.poster_path
                            ? `https://image.tmdb.org/t/p/w200${suggestion.poster_path}`
                            : placeholder
                        }
                        alt={suggestion.title}
                      />
                      <div className='detail-container'>
                        <div className='suggestion-title'>{suggestion.title || suggestion.original_title || suggestion.original_name}</div>
                        <h6 style={{ color: "yellow" }}>
                          {suggestion.vote_average ? suggestion.vote_average.toFixed(1) : 'N/A'} <i className='bx bxs-star'></i>
                        </h6>
                        <div className="suggestion-overview">{suggestion.overview}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
 
        <div className="section mb-3">
          <div className="section__header mb-2">
            <h2>Trending Movies</h2>
            <Link to="/movie">
              <OutlineButton className="small">View more</OutlineButton>
            </Link>
          </div>
          <MovieList category={category.movie} type={movieType.popular} />
        </div>

        <div ref={adRef} className="ad-container section mb-3" style={{ display: "flex", justifyContent: "center" }}></div>
       

        <div className="section mb-3">
          <div className="section__header mb-2">
            <h2>Trending TV</h2>
            <Link to="/tv">
              <OutlineButton className="small">View more</OutlineButton>
            </Link>
          </div>
          <MovieList category={category.tv} type={tvType.popular} />
        </div>

        
  
        <div className="section mb-3">
          <div className="section__header mb-2">
            <h2>Top Rated Movies</h2>
            <Link to="/movie">
              <OutlineButton className="small">View more</OutlineButton>
            </Link>
          </div>
          <MovieList category={category.movie} type={movieType.top_rated} />
        </div>

        <div className="section mb-3">
          <div className="section__header mb-2">
            <h2>Top Rated TV</h2>
            <Link to="/tv">
              <OutlineButton className="small">View more</OutlineButton>
            </Link>
          </div>
          <MovieList category={category.tv} type={tvType.top_rated} />
        </div>

        <div className="section mb-3">
          <LiveTvList category={category.movie} type={movieType.popular} />
        </div>

        {hasFavorites && (
          <div className="section mb-3">
            <div className="section__header mb-2">
              <h2>Wishlist</h2>
              <Link to="/favourites"></Link>
            </div>
            <FavouriteList />
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
