import React, { useEffect, useRef, useState } from "react";
import "../player/player.css";
import axios from 'axios'; 
import { useParams } from "react-router-dom";
import tmdbApi from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";
import { useNavigate } from "react-router-dom";
import MovieList from "../movie-list/MovieList";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import { ref, get, update } from 'firebase/database';
import { auth, db } from '../../firebase'; 
import ReactGA from "react-ga4";

const Player = () => {
  const { category, id } = useParams();
  const [item, setItem] = useState(null);
  const [seasonsDetails, setSeasonsDetails] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(1); // Default to season 1
  const [selectedEpisode, setSelectedEpisode] = useState(1); // Default to episode 1
  const [server, setServer] = useState(1);
  const [isFavorite, setIsFavorite] = useState(false); // State for bookmarked status
  const [ShowFavouriteDialog, setShowFavouriteDialog] = useState(false);
  const navigate = useNavigate();
  const userEmail = localStorage.getItem("email");
  const [loggedIn, setLoggedIn] = useState(userEmail != null);
  const [loading, setLoading] = useState(true);
  const [viewCount, setViewCount] = useState(0); 
  const adRef = useRef(null);
  

  const fetchViewCount = async () => {
    try {
      const viewRef = ref(db, 'movies/' + id);
      const snapshot = await get(viewRef);
      if (snapshot.exists()) {
        const currentCount = snapshot.val().viewCount || 0;
        setViewCount(currentCount);
        // Increment the view count and update it in Firebase
        await update(viewRef, { viewCount: currentCount + 1 });
      } else {
        // If no data exists, initialize viewCount and set it
        await update(viewRef, { viewCount: 1 });
        setViewCount(1);
      }
    } catch (error) {
      console.log('Error fetching or updating view count:', error);
    }
  };
  useEffect(() => {
    fetchViewCount(); 
  }, [id]);

  // Check if the current item is in favorites when component mounts
  useEffect(() => {
    const favorites = JSON.parse(localStorage.getItem(userEmail)) || [];
    const found = favorites.some(
      (fav) => fav.id === item?.id && fav.category === category
    );
    setIsFavorite(found);

    const loadAd = () => {
      if (adRef.current) {
        adRef.current.innerHTML = ""; 

        const adScript = document.createElement("script");
        adScript.type = "text/javascript";
        adScript.innerHTML = `
          atOptions = {
            'key' : '9ff14816438fafa02791cf3fcd9ee660',
            'format' : 'iframe',
            'height' : 90,
            'width' : 728,
            'params' : {}
          };
        `;
        adRef.current.appendChild(adScript);

        const adScriptSrc = document.createElement("script");
        adScriptSrc.type = "text/javascript";
        adScriptSrc.src = "//www.topcreativeformat.com/9ff14816438fafa02791cf3fcd9ee660/invoke.js";
        adRef.current.appendChild(adScriptSrc);
      }
    };

    loadAd();

    return () => {
      if (adRef.current) {
        adRef.current.innerHTML = ""; // Clean up on unmount
      }
    };
  }, [item, category]);

  useEffect(() => {
    if (userEmail) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [userEmail]);

  useEffect(() => {
    const getDetail = async () => {
      const response = await tmdbApi.detail(category, id, { params: {} });
      setItem(response);

      if (category === "tv") {
        const seasonsPromises = response.seasons.map((season) =>
          tmdbApi.season(category, id, season.season_number, { params: {} })
        );
        const seasonsData = await Promise.all(seasonsPromises);
        setSeasonsDetails(seasonsData);

        // Automatically select season 1 and episode 1
        if (seasonsData.length > 0) {
          setSelectedSeason(1);
          const firstSeason = seasonsData.find(
            (season) => season.season_number === 1
          );
          if (firstSeason && firstSeason.episodes.length > 0) {
            setSelectedEpisode(1);
          }
        }
      }

      window.scrollTo(0, 0);
    };

    getDetail();
  }, [category, id]);

  const handleSeasonChange = (seasonNumber) => {
    setSelectedSeason(seasonNumber);
    // Find the first episode of the newly selected season
    const selectedSeasonData = seasonsDetails.find(
      (season) => season.season_number === seasonNumber
    );
    if (selectedSeasonData && selectedSeasonData.episodes.length > 0) {
      setSelectedEpisode(selectedSeasonData.episodes[0].episode_number);
    } else {
      setSelectedEpisode(null);
    }
  };

  const handleEpisodeClick = (episodeNumber) => {
    setSelectedEpisode(episodeNumber);
  };

  const handleServerChange = (serverNumber) => {
    setServer(serverNumber);
  };

  const renderSeasonsDropdown = () => {
    return (
      <select
        className="season-dropdown"
        value={selectedSeason || ""}
        onChange={(e) => handleSeasonChange(parseInt(e.target.value))}
      >
        {seasonsDetails
          .filter((season) => season.season_number !== 0)
          .map((season) => (
            <option key={season.season_number} value={season.season_number}>
              Season {season.season_number}
            </option>
          ))}
      </select>
    );
  };

  const renderEpisodesList = () => {
    if (!selectedSeason) return null;

    const selectedSeasonData = seasonsDetails.find(
      (season) => season.season_number === selectedSeason
    );

    if (!selectedSeasonData) return null;

    return (
      <div>
        <ul className="ep-dropdown-ul">
          {selectedSeasonData.episodes.map((episode) => (
            <li key={episode.episode_number} className="ep-dropdown-li">
              <button
                onClick={() => handleEpisodeClick(episode.episode_number)}
              >
                Ep {episode.episode_number}: {episode.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderPlayer = () => {
    ReactGA.send({ hitType: item.title || item.name, page: window.location.pathname });
    ReactGA.event({
      category: "User",
      action: "Watch" + item.title || item.name,
      label: "User visited Watch Page",
      value: 99,
      nonInteraction: true,
      transport: "xhr",
    });
    if (!selectedEpisode && category === "tv") return null;

    let playerSrc = `https://vidsrc.pro/embed/tv/${id}/${selectedSeason}/${selectedEpisode}`;
    if (server === 2) {
      playerSrc = `https://vidsrc.to/embed/${category}/${id}`;
    }

    if (category === "movie") {
      playerSrc = `https://vidsrc.pro/embed/movie/${id}`;
      if (server === 2) {
        playerSrc = `https://vidsrc.to/embed/${category}/${id}`;
      }
    }

    return (
      <div className="watching_player">
        <div className="watching_player-area">
          <div id="mask-player" style={{ paddingBottom: "56.25%" }}></div>
          <div id="watch-player" style={{ background: "red" }}>
            <iframe
              allowFullScreen={true}
              mozallowfullscreen="true"
              src={playerSrc}
              webkitallowfullscreen="true"
              width="100%"
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const handleAddFavouriteClick = () => {
    if (loggedIn) {
      if (isFavorite) {
        removeFromFavorites();
      } else {
        setShowFavouriteDialog(true);
      }
    } else {
      navigate("/signin");
    }
  };
  const handleAddToFavorites = () => {
    
    if (loggedIn) {
      let favorites = JSON.parse(localStorage.getItem(userEmail)) || [];
      const newFavorite = {
        id: item.id,
        category: category,
        title: item.title || item.name,
        poster_path: item.poster_path,
        timestamp: new Date().toString(),
        vote_average: item.vote_average ,
      };
      favorites.push(newFavorite);
      localStorage.setItem(userEmail, JSON.stringify(favorites));
      setIsFavorite(true); // Update state to show bookmarked icon
      setShowFavouriteDialog(false);
      // Optionally, you can show a success message or update state indicating success
    } else {
      navigate("/signin");
    }
  };



  const removeFromFavorites = () => {
    let favorites = JSON.parse(localStorage.getItem(userEmail)) || [];
    favorites = favorites.filter(
      (fav) => !(fav.id === item.id && fav.category === category)
    );
    localStorage.setItem(userEmail, JSON.stringify(favorites));
    setIsFavorite(false); // Update state to remove bookmarked icon
    // Optionally, you can show a success message or update state indicating success
  };

  
  

  if (!item) {
    return <div>  <div className="spinner-container">
    <div className="pulsing-logo"></div>
  </div></div>;
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Watch - {item.title || item.name}</title>
        <meta name="title" content={item.title || item.name} />
        <link
          rel="canonical"
          href={`https://www.filmtv.site/watch/${category}/${id}`}
        />
        <meta
          name="description"
          content={
            item.overview || "Watch the latest movies and TV shows on Film TV."
          }
        />
        <meta
          name="keywords"
          content={`
    Watch ${item?.title || item?.name} Online Free,
    ${item?.title || item?.name} Online Free,
    Where to watch ${item?.title || item?.name},
    ${item?.title || item?.name} movie free online,
    ${item?.title || item?.name} free online,
    Stream ${item?.title || item?.name} full movie,
    Free streaming of ${item?.title || item?.name},
    ${item?.title || item?.name} HD online free,
    Watch ${item?.title || item?.name} full movie,
    Free movie ${item?.title || item?.name},
    ${item?.title || item?.name} watch online in HD,
    Stream ${item?.title || item?.name} without ads,
    Best sites to watch ${item?.title || item?.name} free,
    ${item?.title || item?.name} streaming services,
    Watch ${item?.title || item?.name} online free in 4K,
    How to watch ${item?.title || item?.name} online,
    Online viewing of ${item?.title || item?.name},
    Watch ${item?.title || item?.name} with subtitles,
    ${item?.title || item?.name} streaming full HD,
    Find ${item?.title || item?.name} online,
    Watch ${item?.title || item?.name} legally online,
    Where to stream ${item?.title || item?.name} full movie,
    ${item?.title || item?.name} full movie online for free,
    Free access to ${item?.title || item?.name} movie,
    ${item?.title || item?.name} free stream,
    ${item?.title || item?.name} movie download free,
    Download ${item?.title || item?.name} full movie,
    Watch ${item?.title || item?.name} on any device,
    Online platforms for ${item?.title || item?.name}
  `}
        />

        <meta
          itemProp="image"
          property="og:image"
          content={apiConfig.originalImage(
            item.poster_path || item.backdrop_path
          )}
        />

        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:type" content="image/png" />
        <meta
          property="og:url"
          content={`https://www.filmtv.site/watch/${category}/${id}`}
        />
      </Helmet>
      <div
        id="wrapper"
        style={{
          textAlign: "center",
          marginTop: "120px",
        }}
      ></div>

      <div className="container">
        <div className="prebreadcrumb">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/" className="Home">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href={`/${category}`} title="Movie" className="Cate">
                  {category}
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href={`/${category}/${id}`} title={item.title || item.name}>
                  {item.title || item.name}
                </a>
              </li>
            </ol>
          </nav>
        </div>
        <div
          className="alert mb-3"
          style={{
            background: "#ffaa00",
            color: "#111",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          If you get any error message when trying to stream, please Refresh the
          page or switch to another streaming server.
        </div>
        <div ref={adRef} className="ad-container section mb-3" style={{ display: "flex", justifyContent: "center" }}></div>

        {category === "tv" && (
          <div className="player-container">
            {renderPlayer()}
            {server === 1 && (
              <div className="dropdown-container">
                {renderSeasonsDropdown()}
                {renderEpisodesList()}
              </div>
            )}
          </div>
        )}

        {category === "movie" && (
          <div className="player-container-movie">{renderPlayer()}</div>
        )}

        <div className="server-buttons">
          <button onClick={() => handleServerChange(1)}>Server 1</button>
          <button onClick={() => handleServerChange(2)}>Server 2</button>
        </div>

        <div ref={adRef} className="ad-container section mb-3 mt-3" style={{ display: "flex", justifyContent: "center" }}></div>

        <div className="detail_page watch_page">
          <div className="detail_page-watch">
            <div className="detail_page-infor">
              <div className="dp-i-content">
                <div className="dp-i-c-poster">
                  <div className="film-poster mb-2">
                    {item.poster_path && (
                      <img
                        className="film-poster-img"
                        src={apiConfig.originalImage(item.poster_path)}
                        title={item.title || item.name}
                        alt={`watch-${item.title || item.name}`}
                      />
                    )}
                  </div>
                  <div className="block-rating" id="block-rating"></div>
                </div>
                <div className="dp-i-c-right">
                  <h2 className="heading-name">
                    <a href={`/${category}/${id}`}>{item.title || item.name}</a>
                    <button onClick={handleAddFavouriteClick}>
                      <i className={`bx ${ isFavorite ? "bxs-bookmark" : "bx-bookmark"}`} style={{fontSize:"25px"}}
                      ></i>
                    </button>
                  </h2>

                  {/* Favourite confirmation dialog */}
                  {ShowFavouriteDialog && (
                    <div className="reset-dialog-overlay">
                      <div className="reset-dialog-content">
                        <h2>Add To Whishlist</h2>
                        <p>
                          Do you want to add {item.title || item.name} to
                          Whishlist
                        </p>
                        <button onClick={handleAddToFavorites}>Yes</button>
                        <button onClick={() => setShowFavouriteDialog(false)}>
                          No
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="dp-i-stats">
                    <span className="item mr-1"></span>
                    <span className="item mr-2">
                      {item.vote_average && (
                        <h6 className=" btn-imdb">
                          TMDB: {item.vote_average.toFixed(1)}
                          <h6 style={{color:"white",top:"0"}}>  <FontAwesomeIcon icon={faEye} />  {formatNumber(viewCount)}</h6>
                        </h6>
                        
                      )}
                     
                
                
                    </span>
                  </div>

                  <div className="description">
                    {item.overview && item.overview}
                  </div>
                  <div className="elements">
                    <div className="row">
                      <div className="col-xl-5 col-lg-6 col-md-8 col-sm-12">
                        <div className="row-line">
                          <span className="type">
                            <strong>Released: </strong>
                          </span>{" "}
                          {item.release_date}
                        </div>
                        <div className="row-line">
                          <span className="type">
                            <strong>Genre: </strong>
                          </span>
                      
                          {item.genres &&
                            item.genres.map((genre, index) => (
                              <span key={genre.id}>
                                <a
                                  href={`/genre/${genre.name.toLowerCase()}`}
                                  title={genre.name}
                                >
                                  {genre.name}
                                </a>
                                {index < item.genres.length - 1 && ", "}
                              </span>
                            ))}
                        </div>
                        <div className="row-line">
                          <span className="type">
                            <strong>Casts: </strong>
                          </span>
                          {item.casts ? item.casts.join(", ") : "N/A"}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-4 col-sm-12">
                        <div className="row-line">
                          <span className="type">
                            <strong>Duration: </strong>
                          </span>{" "}
                          {item.runtime} min
                        </div>
                        <div className="row-line">
                          <span className="type">
                            <strong>Country: </strong>
                          </span>
                          {item.production_countries &&
                            item.production_countries.map((country) => (
                              <span key={country.iso_3166_1}>
                                <a
                                  href={`/${country.iso_3166_1.toLowerCase()}.html`}
                                  title={country.name}
                                >
                                  {country.name}
                                </a>
                              </span>
                            ))}
                        </div>
                        <div className="row-line">
                          <span className="type">
                            <strong>Production: </strong>
                          </span>
                          {item.production_companies &&
                            item.production_companies.map((company) => (
                              <span key={company.id}>
                                <a
                                  href={`/${company.name.toLowerCase()}.html`}
                                  title={company.name}
                                >
                                  {company.name}
                                </a>
                                ,
                              </span>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
            {/* <div ref={adRef} className="ad-container section mb-3" style={{ display: "flex", justifyContent: "center" }}></div> */}

            <div className="section mb-3">
              <div className="section__header mb-2">
                <h2>Similar</h2>
              </div>
              <MovieList category={category} type="similar" id={item.id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const formatNumber = (number) => {
  if (number >= 1_000_000) {
    return (number / 1_000_000).toFixed(1) + 'm';
  } else if (number >= 1_000) {
    return (number / 1_000).toFixed(1) + 'k';
  } else {
    return number;
  }
};

export default Player;
