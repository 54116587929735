import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import debounce from "lodash.debounce";
import "./movie-grid.scss";

import MovieCard from "../movie-card/MovieCard";
import Button, { OutlineButton } from "../button/Button";
import Input from "../input/Input";
import tmdbApi, { category, movieType, tvType } from "../../api/tmdbApi";
import logo from "../../assets/preview.png";
import placeholder from "../../assets/placeholder.jpg";

const MovieGrid = (props) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const { keyword } = useParams();
  const adRef = useRef(null);

  useEffect(() => {
    const getList = async () => {
      let response = null;
      if (keyword === undefined) {
        const params = {};
        switch (props.category) {
          case category.movie:
            response = await tmdbApi.getMoviesList(movieType.upcoming, {
              params,
            });
            break;
          default:
            response = await tmdbApi.getTvList(tvType.popular, { params });
        }
      } else {
        const params = { query: keyword };
        response = await tmdbApi.search(props.category, { params });
      }

      setItems(response.results);
      setTotalPage(response.total_pages);
    };
    getList();
    
    const loadAd = () => {
      if (adRef.current) {
        adRef.current.innerHTML = ""; 

        const adScript = document.createElement("script");
        adScript.type = "text/javascript";
        adScript.innerHTML = `
          atOptions = {
            'key' : '9ff14816438fafa02791cf3fcd9ee660',
            'format' : 'iframe',
            'height' : 90,
            'width' : 728,
            'params' : {}
          };
        `;
        adRef.current.appendChild(adScript);

        const adScriptSrc = document.createElement("script");
        adScriptSrc.type = "text/javascript";
        adScriptSrc.src = "//www.topcreativeformat.com/9ff14816438fafa02791cf3fcd9ee660/invoke.js";
        adRef.current.appendChild(adScriptSrc);
      }
    };

    loadAd();

    return () => {
      if (adRef.current) {
        adRef.current.innerHTML = ""; // Clean up on unmount
      }
    };
  }, [props.category, keyword]);

  const loadMore = async () => {
    let response = null;
    if (keyword === undefined) {
      const params = { page: page + 1 };
      switch (props.category) {
        case category.movie:
          response = await tmdbApi.getMoviesList(movieType.upcoming, {
            params,
          });
          break;
        default:
          response = await tmdbApi.getTvList(tvType.popular, { params });
      }
    } else {
      const params = { page: page + 1, query: keyword };
      response = await tmdbApi.search(props.category, { params });
    }
    setItems([...items, ...response.results]);
    setPage(page + 1);
  };

  return (
    <>
   <div className="section mb-3" style={{ justifyContent: "end", display: "flex" }}>
        <MovieSearch category={props.category} keyword={keyword} />
      </div>
      <div ref={adRef} className="ad-container section mb-3" style={{ display: "flex", justifyContent: "center" }}></div>
       
      <div className="movie-grid">
        {items.map((item, i) => (
          <MovieCard category={props.category} item={item} key={i} />
        ))}
      </div>
      {page < totalPage ? (
        <div className="movie-grid__loadmore">
          <OutlineButton className="small" onClick={loadMore}>
            Load more
          </OutlineButton>
        </div>
      ) : null}
    </>
  );
};

const MovieSearch = (props) => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState(props.keyword ? props.keyword : "");
  const [suggestions, setSuggestions] = useState([]);

  const fetchSuggestions = useCallback(
    debounce(async (query) => {
      if (query.trim().length > 0) {
        const params = { query: query };
        const response = await tmdbApi.search(props.category, { params });
        setSuggestions(response.results);
      } else {
        setSuggestions([]);
      }
    }, 300),
    [props.category]
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setKeyword(value);
    fetchSuggestions(value);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const goToSearch = useCallback(() => {
    if (keyword.trim().length > 0) {
      navigate(`/${category[props.category]}/search/${keyword}`);
      setSuggestions([]); // Hide suggestions after selecting
    }
  }, [keyword, props.category, navigate]);

  useEffect(() => {
    const enterEvent = (e) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        goToSearch();
      }
    };
    document.addEventListener("keyup", enterEvent);
    return () => {
      document.removeEventListener("keyup", enterEvent);
    };
  }, [keyword, goToSearch]);

  return (
    <div className="movie-search">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{capitalizeFirstLetter(props.category)}</title>
        <link
          rel="canonical"
          href={`https://www.filmtv.site/${props.category}`}
        />
        <meta property="og:type" content="website" />
        <link
          rel="canonical"
          href={`https://www.filmtv.site/${props.category}`}
        />
        <meta
          property="og:url"
          content={`https://www.filmtv.site/${props.category}`}
        />
        <meta
          property="og:title"
          content={capitalizeFirstLetter(props.category)}
        />
        <meta
          property="og:description"
          content={`Watch the best ${props.category} movies and TV shows online for free in HD. Enjoy an extensive collection of ${props.category} content, from classic hits to the latest releases, all at Film TV.`}
        />
        <meta property="og:image" itemProp="image" content={logo} />
      </Helmet>

      <Input
        type="text"
        placeholder="Enter keyword"
        value={keyword}
        onChange={handleInputChange}
      />
      <Button className="small" onClick={goToSearch}>
        Search
      </Button>

      {suggestions.length > 0 && (
        <div className="suggestions">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className="suggestion"
              onClick={() => {
                setKeyword(suggestion.title);
                setSuggestions([]);
                navigate(`/${category[props.category]}/${suggestion.id}`);
              }}
            >
              <div className="show-container">
                <img
                  src={
                    suggestion.poster_path
                      ? `https://image.tmdb.org/t/p/w200${suggestion.poster_path}`
                      : placeholder
                  }
                  alt={suggestion.title}
                />

                <div className="detail-container">
                  <div className="suggestion-title">
                    {suggestion.title ||
                      suggestion.original_titel ||
                      suggestion.original_name}
                  </div>
                  <h6 style={{ color: "yellow" }}>
                    {suggestion.vote_average
                      ? suggestion.vote_average.toFixed(1)
                      : "N/A"}{" "}
                    <i className="bx bxs-star"></i>
                  </h6>

                  <div className="suggestion-overview">
                    {suggestion.overview}
                  </div>
                </div>
              </div>
            

            </div>
            
          ))}
        </div>
        
      )}
      
    </div>
    
  );
};

export default MovieGrid;
