
const apiConfig = {
    baseUrl: 'https://api.themoviedb.org/3/',
    apiKey: 'd5e72dab9b2608cab3bb50a5b043531c',
    originalImage: (imgPath) => `https://image.tmdb.org/t/p/original/${imgPath}`,
    w500Image: (imgPath) => `https://image.tmdb.org/t/p/w500/${imgPath}`,
    movielist: "https://vidsrc.pro/list/movie.json",
    tvlist: "https://vidsrc.pro/list/tv.json"

}

export default apiConfig;