import React from 'react';
import './live-card.scss';
import { Link } from 'react-router-dom';

const LiveTvCard = (props) => {
    const item = props.item;
    const bg = item.image;
    const channel = item.channel; 
    const url = item.url;
  
    return (
        <Link to={url}className="movie-card-link" >
            <div className="live-card" style={{ backgroundImage: `url(${bg})` }} >
                <div className="live-dot"></div> {/* Red dot for live indicator */}
                <div className="pulse-dot"></div> {/* Pulsating dot beneath */}
                <div className="live"></div>
            </div>
            <h6 className='title-live' style={{ color: "white" ,transform:"translateY(-5px)"}}>{item.title || item.name}</h6>
        </Link>
    );
};

export default LiveTvCard;
