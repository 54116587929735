import React, { useState, useEffect } from 'react';
import './movie-card.scss';
import { Link } from 'react-router-dom';
import Button from '../button/Button';
import { Helmet } from 'react-helmet';
import apiConfig from '../../api/apiConfig';
import { ref, get, update } from 'firebase/database';
import { auth, db } from '../../firebase'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import placeholderImage from '../../assets/placeholder.jpg'; 


const GenreCard = (props) => {
  const item = props.item;
  const category = props.category === 'movie' ? 'movies' : 'movie'; // Adjust as needed

  const link = `/${category}/${item.id}`;
  const actualImage = apiConfig.w500Image(item.poster_path || item.backdrop_path);
  const [bg, setBg] = useState(placeholderImage);
  const [viewCount, setViewCount] = useState(0);

  const fetchViewCount = async () => {
    try {
      const viewRef = ref(db, 'movies/' + item.id);
      const snapshot = await get(viewRef);
      if (snapshot.exists()) {
        setViewCount(snapshot.val().viewCount || 0);
      } else {
        console.log('No data available');
      }
    } catch (error) {
      console.log('Error fetching view count:', error);
    }
  };

  useEffect(() => {
    fetchViewCount(); // Fetch view count when the component mounts
    const img = new Image();
    img.src = actualImage;
    img.onload = () => setBg(actualImage);
    img.onerror = () => setBg(placeholderImage);
  }, [actualImage, item.id]);


  useEffect(() => {
    const img = new Image();
    img.src = actualImage;
    img.onload = () => setBg(actualImage);
    img.onerror = () => setBg(placeholderImage);
  }, [actualImage]);


  return (
    <>
    
    <Link to={link} className="movie-card-link">
        <div className="movie-card" style={{ backgroundImage: `url(${bg})` }}>
          <Button >
            <i className="bx bx-play" style={{ fontSize: "50px", border:"none" }}></i>
          </Button>
          <div className='ratings'>
            <h6>{item.vote_average ? item.vote_average.toFixed(1) : 'N/A'} <i className='bx bxs-star'></i></h6>
          </div>
          <div className="release-year">{item && item.release_date && item.release_date.split('-')[0]}</div>
          <h6 className='views'>
          <FontAwesomeIcon className='eye' icon={faEye} />  {formatNumber(viewCount)}
        </h6>
        </div>
        <h6 className='title-film' style={{ color: "white" }}>{item.title || item.name}</h6>
      </Link>
    </>
  );
};
const formatNumber = (number) => {
  if (number >= 1_000_000) {
    return (number / 1_000_000).toFixed(1) + 'm';
  } else if (number >= 1_000) {
    return (number / 1_000).toFixed(1) + 'k';
  } else {
    return number;
  }
};

export default GenreCard;
