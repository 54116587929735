import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import astronautImage from './images/astronaut.svg';
import planetImage from './images/planet.svg';
import './404.css';

export default function ErrorPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const goHome = () => {
      navigate('/'); 
    };

    const button = document.querySelector('.denied__link');
    if (button) {
      button.addEventListener('click', goHome);
    }

    return () => {
      if (button) {
        button.removeEventListener('click', goHome);
      }
    };
  }, [navigate]);

  const htmlString = ReactDOMServer.renderToStaticMarkup(
    <body className="permission_denied">
      <div className="denied__wrapper">
        <h1>404</h1>
        <h3>
          LOST IN <span>SPACE</span> FilmTv Hmm, looks like that page doesn't
          exist.
        </h3>
        <img id="astronaut" src={astronautImage} alt="Astronaut" />
        <img id="planet" src={planetImage} alt="Planet" />
        <a href="#">
          <button className="denied__link">Go Home</button>
        </a>
      </div>
    </body>
  );

  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}
