import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from "../../firebase";
import {  sendPasswordResetEmail } from 'firebase/auth';




export default function Lostpass() {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, email );
      setErrorMessage('');
      alert('Password reset email sent successfully.');
      navigate('/signin');
    } catch (error) {
      setErrorMessage(error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    const userEmail = localStorage.getItem("email");

    if (userEmail != null) {
      navigate("/");
    }
  }, []);

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          {/* form start */}
          <form className="user" onSubmit={handleResetPassword}>
            <span className="login100-form-title p-b-26">Reset Password</span>
            <span className="login100-form-title p-b-48">
              <i className="bi bi-film"></i>
             
            </span>

            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <div className="wrap-input100 validate-input">
              <input
                className="input100"
                type="email"
                name="email"
                required
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span
                className="focus-input100"
                data-placeholder="email"
              ></span>
            </div>

            <button className="signinBtn" type="submit" name="submit" disabled={loading}>
          {loading ? "Reseting..." : "Reset"}
        </button>
            <h6 className="register-form-call">
              Wanna Go Back ?
              <Link to="/signin" className="registerlink">
                {" "}
                Click Here
              </Link>
            </h6>
          </form>
        </div>
      </div>
    </div>
  );
}
