import React, { useState, useEffect, useRef } from 'react';
import { getDatabase, ref, onValue, child } from 'firebase/database';
import { db } from '../../firebase'; // Ensure this import points to your Firebase configuration file

const FriendPage = () => {
  const [adminVideoState, setAdminVideoState] = useState({ event: '', currentTime: 0 });
  const videoRef = useRef(null);

  const uId = localStorage.getItem('uid'); // The friend's user ID

  useEffect(() => {
    if (!uId) {
      // Redirect to login if user details are not found in local storage
      window.location.href = '/login';
    } else {
      const dbRef = ref(getDatabase());
      const userRef = child(dbRef, `users/${uId}`);

      const unsubscribe = onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData && userData.videoState) {
          setAdminVideoState(userData.videoState);
          handleRemoteVideoControl(userData.videoState);
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [uId]);

  const handleRemoteVideoControl = ({ event, currentTime }) => {
    const video = videoRef.current;
    switch (event) {
      case 'play':
        video.currentTime = currentTime;
        video.play();
        break;
      case 'pause':
        video.currentTime = currentTime;
        video.pause();
        break;
      case 'seeked':
        video.currentTime = currentTime;
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ marginTop: '250px' }}>
      <h2>Friend Page</h2>
      <p>Live streaming the admin's video:</p>
      <video ref={videoRef} width="640" height="360" controls>
        <source src="https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default FriendPage;
